.tooltip_custom {
  position: relative;
  position: relative;
  display: inline-block;
  display: inline-block;
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  text-align: center;
  --tooltip-tail: 3px;
  --tooltip-color: hsl(var(--n));
  --tooltip-text-color: hsl(var(--nc));
  --tooltip-tail-offset: calc(100% + 1px - var(--tooltip-tail));
}
.tooltip_custom:before {
  position: absolute;
  pointer-events: none;
  content: attr(data-tip);
  transform: translateX(-50%);
  top: auto;
  left: 50%;
  right: auto;
  bottom: 60px;
  max-width: 20rem /* 320px */;
  border-radius: 0.25rem /* 4px */;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  width: max-content;
}
.tooltip_custom:before,
.tooltip_custom:after {
  opacity: 0;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-delay: 100ms;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tooltip_custom:before,
.tooltip_custom:after {
  opacity: 0;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-delay: 100ms;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tooltip_custom:after {
  position: absolute;
  position: absolute;
  content: '';
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  transform: translateX(-50%);
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: 54px;
}
.tooltip_custom.tooltip-open:before,
.tooltip_custom.tooltip-open:after,
.tooltip_custom:hover:before,
.tooltip_custom:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}
.tooltip_custom.tooltip-open:before,
.tooltip_custom.tooltip-open:after,
.tooltip_custom:hover:before,
.tooltip_custom:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}
.tooltip_custom.tooltip-open:before,
.tooltip_custom.tooltip-open:after,
.tooltip_custom:hover:before,
.tooltip_custom:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}
.tooltip_custom.tooltip-open:before,
.tooltip_custom.tooltip-open:after,
.tooltip_custom:hover:before,
.tooltip_custom:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}

@media (min-width: 640px) {
  .tooltip_custom_media_query:before {
    transform: translateY(-50%);
    top: 50%;
    left: auto;
    right: 60px;
    bottom: auto;
  }
  .tooltip_custom_media_query:after {
    transform: translateY(-50%);
    border-color: transparent transparent transparent var(--tooltip-color);
    top: 50%;
    left: auto;
    right: 54px;
    bottom: auto;
  }
}

@media (min-width: 768px) {
  .tooltip_custom_media_query:before {
    transform: translateY(-50%);
    top: 50%;
    left: 61px;
    right: auto;
    bottom: auto;
  }
  .tooltip_custom_media_query:after {
    transform: translateY(-50%);
    border-color: transparent var(--tooltip-color) transparent transparent;
    top: 50%;
    left: 55px;
    right: auto;
    bottom: auto;
  }
}